import React from "react";
import {Queue} from "../../types";
import {ErrorDisplay} from "./errors";
import {SVQueueHistorySelect} from "./queues";
import {FancyJsonViewer, JsonDiff, JsonFormatterSwitcher, RawJsonViewer,} from "./json";
import {SpinningWheel} from "../../components/SpinningWheel";
import Stack from "@mui/material/Stack";

export class SVContent extends React.Component<{
    error: any;
    queue: Queue | null;
    history: any[];
    timestamps: any;
    schema: any;
    checked: boolean;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    loading: boolean;
    schemaDiff: boolean;
    schemaA: string;
    schemaB: string;
}> {
    render() {
        return (
            <Stack width={600}>
                <ErrorDisplay error={this.props.error}/>
                {this.props.queue?.id && this.props.history.length > 0 && (
                    <div className={"section"}>
                        <SVQueueHistorySelect
                            history={this.props.history}
                            timestamps={this.props.timestamps}
                            queue={this.props.queue}
                        />
                        {this.props.schema && !this.props.schemaDiff && (
                            <div>
                                <JsonFormatterSwitcher
                                    checked={this.props.checked}
                                    onChange={this.props.onChange}
                                />
                                {this.props.checked &&
                                    !this.props.schemaDiff &&
                                    this.props.schema && (
                                        <FancyJsonViewer text={this.props.schema}/>
                                    )}
                                {!this.props.checked &&
                                    !this.props.schemaDiff &&
                                    this.props.schema && (
                                        <RawJsonViewer text={this.props.schema}/>
                                    )}
                            </div>
                        )}
                        {this.props.schemaDiff && (
                            <JsonDiff old={this.props.schemaA} new={this.props.schemaB}/>
                        )}
                    </div>
                )}
                {this.props.loading && <SpinningWheel/>}
            </Stack>
        );
    }
}
