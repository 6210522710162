import React from "react";
import {Queue} from "../../types";
import {SingleQueue} from "../../components/QueuePicker";
import HistoryMenu from "./history";

export class SVQueueSelect extends React.Component<{
    token: string;
    queue: (
        value: ((prevState: Queue | null) => Queue | null) | Queue | null
    ) => void;
    currentQueue: Queue | null;
}> {
    /* First selector of the queue - a user will select the
     * queue to be used and then the rest is derived from
     * the selection. */
    render() {
        return (
            <div style={{minWidth: 600}}>
                <SingleQueue
                    token={this.props.token}
                    setQueue={this.props.queue}
                    currentQueue={this.props.currentQueue}
                />
            </div>
        );
    }
}

export class SVQueueHistorySelect extends React.Component<{
    history: any[];
    timestamps: any;
    queue: any;
}> {
    /* After a queue schema is selected its version has to be
        specified by this dropdown. It is not reused for the SingleQueue
        since it would require more changes. */
    render() {
        return (
            <HistoryMenu
                history={this.props.history}
                setTimestamps={this.props.timestamps}
                queue={this.props.queue}
            />
        );
    }
}
