import Stack from "@mui/material/Stack";
import React from "react";
import "../styles.css";
import {SettingsColor} from "./components/Settings/settingsColor";
import {SettingsLoader} from "./components/Settings/settingsLoader";
import {SettingsLogo} from "./components/Settings/settingsLogo";
import {SettingsUserImage} from "./components/Settings/settingsUserImage";
import {SettingsReset} from "./components/Settings/settingsReset";
import {OrganizationButton} from "./components/OrganizationButton/OrganizationButton";
import {Typography} from "@mui/material";
import {SettingsTheme} from "./components/Settings/settingsTheme";
import {SettingsPoweredBy} from "./components/Settings/settingsPoweredBy";
import {Application} from "../../components/Application";
import {SettingsFont} from "./components/Settings/settingsFont";

const UISettings = (props: { token: string }) => {
    return (
        <Application>
            <Typography variant={"h3"}>UI Settings</Typography>
            <Typography variant={"caption"} width={450}>
                The branding color can be set in UI or as HEX or RGB (and other
                notations). Loader is an SVG representation of loading animation,
                while Logo and User image are commonly used image formats such as
                JPEG, PNG, GIF or SVG.
            </Typography>
            <Stack direction={"row"} spacing={2} margin={"0 auto"} marginTop={"5%"}>
                <SettingsColor token={props.token} suffix_url={"color"}/>
                <SettingsLoader token={props.token} suffix_url={"loader"}/>
                <SettingsLogo token={props.token} suffix_url={"logo"}/>
                <SettingsUserImage token={props.token} suffix_url={"user-image"}/>
                <SettingsTheme token={props.token} suffix_url={"theme"}/>
                <SettingsPoweredBy token={props.token} suffix_url={"powered-by"}/>
            </Stack>
            <SettingsFont token={props.token}/>
            <Stack direction={"row"} spacing={2} margin={"0 auto"}>
                <SettingsReset token={props.token} suffix_url={"reset"}/>
                <OrganizationButton token={props.token}/>
            </Stack>
        </Application>
    );
};

export default UISettings;
