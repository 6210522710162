import React from "react";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

export class SpinningWheel extends React.Component {
    render() {
        return (
            <Box sx={{display: "flex", justifyContent: "center", marginTop: 5}}>
                <CircularProgress/>
            </Box>
        );
    }
}
