import React, {useEffect, useState} from "react";
import {Field, Queue} from "../../../types";
import {API_URL} from "../../../config";
import {BiLoaderAlt} from "react-icons/bi";
import {Chart} from "react-google-charts";
import {zip} from "lodash";

type Props = {
    queue: Queue;
    field: Field;
    token: string;
    clearField: Function;
};

type Data = {
    names: string[];
    values: string[];
};

const Histogram = ({queue, field, clearField, token}: Props) => {
    const [data, setData] = useState<Data | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        const listener = (event: KeyboardEvent) => {
            if (event.keyCode === 27) clearField();
        };
        document.addEventListener("keydown", listener);
        return () => document.removeEventListener("keydown", listener);
    });

    useEffect(() => {
        setLoading(true);
        const params = `queue_id=${queue.id}&schema_id=${field.id}`;
        fetch(`${API_URL}/api/fields/histogram?${params}`, {
            headers: {Authorization: token},
            credentials: "include",
        })
            .then(r => r.json())
            .then(({data: _data}) => setData(_data))
            .catch(console.error)
            .finally(() => setLoading(false));
    }, [queue, field, token]);

    return (
        <div>
            {loading ? (
                <BiLoaderAlt className="Loader"/>
            ) : data?.values.length ? (
                <React.Fragment>
                    <div
                        style={{
                            position: "fixed",
                            color: "black",
                            top: 75,
                            left: 50,
                            zIndex: 1,
                            fontSize: 14,
                            fontWeight: 800,
                            cursor: "pointer",
                        }}
                        onClick={() => clearField()}
                    >
                        (Close by pressing ESC)
                    </div>
                    <Chart
                        style={{
                            position: "fixed",
                            top: 55,
                            left: 0,
                        }}
                        width="100%"
                        height="calc(100% - 55px)"
                        chartType="ColumnChart"
                        loader={<div>Loading Chart</div>}
                        data={[["Field", "Occurrences"], ...zip(data?.names, data?.values)]}
                        options={{
                            title: `Value histogram for '${field.id}'`,
                            chartArea: {width: "70%"},
                            hAxis: {
                                title: "Possible values",
                            },
                        }}
                        legendToggle
                    />
                </React.Fragment>
            ) : (
                "no data found"
            )}
        </div>
    );
};

export default Histogram;
