import {useOrganizationSettings} from "../../hooks/hooks";
import React, {useEffect, useState} from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import {API_URL} from "../../../../config";

export const SettingsLogo = (props: { token: string; suffix_url: string }) => {
    let defaultSettings = useOrganizationSettings(props.token, props.suffix_url);
    const [settings, setSettings] = useState<string>("");
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (defaultSettings) {
            setSettings(defaultSettings);
        }
    }, [defaultSettings]);

    function selectFile(event: React.ChangeEvent<HTMLInputElement>) {
        if (event.target.files) {
            const file = event.target.files[0];
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = event => {
                if (event.target) {
                    const data = event.target.result;
                    postSettings(data);
                    if (data) {
                        setSettings(String(data));
                    }
                }
            };
        }
    }

    function postSettings(data: ArrayBuffer | string | null) {
        if (data) {
            setIsLoading(true);
            fetch(`${API_URL}/ui-settings/${props.suffix_url}`, {
                method: "POST",
                headers: {
                    Authorization: props.token,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({file: data}),
                credentials: "include",
            })
                .then(r => r.json())
                .catch(console.error)
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }

    return (
        <div className={"UISettingsContainer"}>
            {defaultSettings && (
                <Stack>
                    <label style={{fontSize: "small"}}>Logo image</label>
                    <object
                        id={"OrganizationLoader"}
                        type="image/jpeg"
                        data={settings}
                        style={{height: 100}}
                    >
                        Logo
                    </object>
                    <Button
                        component="label"
                        id={"LoadingButton"}
                        style={{width: 90, margin: "0 auto"}}
                        hidden={!isLoading}
                        variant={"text"}
                    >
                        Upload
                        <input
                            type="file"
                            accept={"image/svg image/png image/jpg image/jpeg image/gif"}
                            hidden
                            onChange={event => selectFile(event)}
                        />
                    </Button>
                </Stack>
            )}
            {!defaultSettings && <CircularProgress style={{marginTop: 50}}/>}
        </div>
    );
};
