import Select, {MultiValue, SingleValue} from "react-select";
import {useQueuesList} from "../../hooks/queues";
import {Queue} from "../../types";
import {find, uniqBy} from "lodash";

type Option = {
    label: string;
    value: string;
};
type SingleQueueProps = {
    token: string;
    currentQueue: Queue | null;
    setQueue: (q: Queue | null) => void;
};
type MultiQueuesProps = {
    token: string;
    currentQueues: Queue[];
    setQueues: (qs: Queue[]) => void;
};

export const SingleQueue = ({
                                setQueue,
                                token,
                                currentQueue,
                            }: SingleQueueProps) => {
    const queues = useQueuesList(token);
    return (
        <div className="Selection">
            <label>Select Queue</label>
            <Select
                // @ts-ignore
                title="Queue"
                defaultMenuIsOpen
                // @ts-ignore
                options={queues.map(({id, name}) => ({
                    value: String(id),
                    label: `${name} [${id}]`,
                }))}
                isSearchable
                autoFocus
                styles={styles}
                placeholder="Queue"
                // @ts-ignore
                onChange={(option: SingleValue<Option>) => {
                    setQueue(
                        option ? find(queues, {id: Number(option.value)}) || null : null
                    );
                }}
                value={{value: currentQueue?.id, label: currentQueue?.name}}
            />
        </div>
    );
};

export const MultiQueue = ({
                               token,
                               currentQueues,
                               setQueues,
                           }: MultiQueuesProps) => {
    const queues = useQueuesList(token);
    return (
        <div className="Selection">
            <label>Select Queue</label>
            <Select
                // @ts-ignore
                title="Queue"
                defaultMenuIsOpen
                // @ts-ignore
                options={queues.map(({id, name}) => ({
                    value: String(id),
                    label: `${name} [${id}]`,
                }))}
                isSearchable
                autoFocus
                isMulti
                styles={styles}
                placeholder="Queue"
                // @ts-ignore
                onChange={(options: MultiValue<Option>) => {
                    const _queues = options.map(
                        option => find(queues, {id: Number(option.value)}) as Queue
                    );
                    setQueues(uniqBy(_queues, "id"));
                }}
                value={currentQueues.map(q => ({value: q?.id, label: q?.name}))}
            />
        </div>
    );
};

const styles = {
    control: (base: any) => ({...base, borderRadius: 50}),
    option: (base: any) => ({...base, color: "black"}),
};
