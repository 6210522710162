import {
    Alert,
    AlertTitle,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
    Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import React, {Dispatch} from "react";
import Stack from "@mui/material/Stack";

function ErrorAlert(props: { error: string }) {
    return (
        <Alert severity={"error"}>
            <AlertTitle>
                <strong>Confirmation was unsuccessful</strong>
            </AlertTitle>
            {props.error}
        </Alert>
    );
}

function DialogText(props: {
    error: string | null;
    enabled: boolean;
    orgName: string;
    orgID: number | null;
}) {
    return (
        <Stack direction={"column"} spacing={2} style={{marginBottom: 30}}>
            {props.error && <ErrorAlert error={props.error}/>}
            <Typography>
                You are about to <b>{props.enabled ? "disable" : "enable"}</b> Data
                Capture Lock for the organization name <b>{props.orgName}</b> [ID:{" "}
                <code>{props.orgID}</code>]
            </Typography>
            <Typography variant={"caption"}>
                This operation will make changes in the customer's organization:
                {props.enabled && (
                    <div className={"list"}>
                        <li>
                            Set all users to user group <code>viewer</code>
                        </li>
                        <li>
                            Upload and enable serverless function informing user about being
                            blocked
                        </li>
                        <li>
                            Set queues automation to <code>never</code>
                        </li>
                    </div>
                )}
                {!props.enabled && (
                    <div className={"list"}>
                        <li>
                            Set all users to original user group before disabling the
                            organization
                        </li>
                        <li>
                            Disable serverless function informing user about being blocked
                        </li>
                        <li>
                            Set queues automation to value before disabling the organization
                        </li>
                    </div>
                )}
            </Typography>
            <Typography variant={"body1"}>
                PLEASE MAKE SURE YOU ARE LOGGED FROM ELIS ADMIN!
            </Typography>
            <Typography>
                To confirm this action, please enter an <b>organization ID</b> and press
                confirm.
            </Typography>
        </Stack>
    );
}

export function ConfirmDialog(props: {
    open: boolean;
    onClose: () => void;
    enabled: boolean;
    handleCancel: () => void;
    handleConfirm: () => void;
    orgName: string;
    orgID: number | null;
    setConfirmationID: Dispatch<number | null>;
    error: string | null;
}) {
    return (
        <Dialog open={props.open} onClose={props.onClose}>
            <DialogTitle>Confirm operation</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <DialogText
                        error={props.error}
                        enabled={props.enabled}
                        orgName={props.orgName}
                        orgID={props.orgID}
                    />
                </DialogContentText>
                <TextField
                    autoFocus
                    id="organization-id"
                    label="Organization ID"
                    type="number"
                    fullWidth
                    variant="outlined"
                    onChange={e => {
                        props.setConfirmationID(Number(e.target.value));
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleCancel}>Cancel</Button>
                <Button onClick={props.handleConfirm}>Confirm</Button>
            </DialogActions>
        </Dialog>
    );
}
