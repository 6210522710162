import Select from "react-select";
import {useEffect, useState} from "react";

const styles = {
    control: (base: any) => ({...base, borderRadius: 50}),
    option: (base: any) => ({...base, color: "black"}),
};

const HistoryMenu = (props: any) => {
    /* A history menu that shows user
     * all versions of selected queue */
    const [selectedOptions, setSelectedOptions] = useState([]);

    useEffect(() => {
        let options: string[] = [];
        selectedOptions.forEach(function (selectedOption) {
            // @ts-ignore
            options.push(selectedOption.value);
        });
        props.setTimestamps(options);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedOptions]);

    return (
        <div className="Selection">
            <label>Select Version</label>
            <Select
                // @ts-ignore
                title="History"
                options={props.history}
                value={selectedOptions}
                isSearchable
                autoFocus
                styles={styles}
                placeholder="Version"
                // @ts-ignore
                onChange={o => setSelectedOptions(o)}
                defaultMenuIsOpen
                isMulti={true}
                isOptionDisabled={() => selectedOptions.length >= 2}
            />
        </div>
    );
};

export default HistoryMenu;
