import {Dispatch, useEffect, useState} from "react";
import {API_URL} from "../../../config";
import {Agendas} from "../interfaces/agendas";
import {ErrorInterface} from "../interfaces/error";

export const useAgendaList = (
    token: string,
    setError: Dispatch<ErrorInterface>,
    setIsLoading: Dispatch<boolean>
) => {
    const [data, setData] = useState<Agendas | null>(null);

    useEffect(() => {
        setIsLoading(true)
        fetch(`${API_URL}/api/agendas`, {
            headers: {Authorization: token},
            credentials: "include",
        })
            .then(r => r.json())
            .then(response => {
                if (response.success) {
                    const results: Agendas = response.results;
                    setData(results);
                } else {
                    setError({
                        message: response.message,
                        detail: response.detail,
                        severity: "error",
                    });
                }
            })
            .catch(console.error).finally(() => setIsLoading(false));
        // eslint-disable-next-line
    }, []);

    return data;
};
