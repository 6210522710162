import {useOrganizationSettings} from "../../hooks/hooks";
import React, {Dispatch, useEffect, useState} from "react";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import {API_URL} from "../../../../config";
import {Checkbox} from "@mui/material";

function patchPoweredBy(
    status: string,
    token: string,
    setIsLoading: Dispatch<boolean>
) {
    setIsLoading(true);
    fetch(`${API_URL}/ui-settings/powered-by/${encodeURIComponent(status)}`, {
        method: "POST",
        headers: {Authorization: token},
        credentials: "include",
    })
        .then(r => r.json())
        .catch(console.error)
        .finally(() => {
            setIsLoading(false);
        });
}

export const SettingsPoweredBy = (props: {
    token: string;
    suffix_url: string;
}) => {
    const defaultSettings = useOrganizationSettings(
        props.token,
        props.suffix_url
    );
    const [settings, setSettings] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [hasChanged, setHasChanged] = useState(false);

    useEffect(() => {
        setSettings(defaultSettings);
    }, [defaultSettings]);

    useEffect(() => {
        if (hasChanged && settings !== null) {
            patchPoweredBy(settings, props.token, setIsLoading);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [settings]);

    return (
        <React.Fragment>
            <div className={"UISettingsContainer"}>
                <Stack>
                    <label style={{fontSize: "small"}}>Powered by</label>
                    {settings !== null && !isLoading && (
                        <React.Fragment>
                            <Checkbox
                                checked={settings === "enabled"}
                                onChange={event => {
                                    setHasChanged(true);
                                    setSettings(event.target.checked ? "enabled" : "disabled");
                                }}
                            />
                        </React.Fragment>
                    )}
                    {settings === null && (
                        <CircularProgress style={{margin: "0 auto", marginTop: 35}}/>
                    )}
                    {isLoading && (
                        <CircularProgress style={{margin: "0 auto", marginTop: 35}}/>
                    )}
                </Stack>
            </div>
        </React.Fragment>
    );
};
