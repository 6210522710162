import React, {useState} from "react";
import CircularProgress from "@mui/material/CircularProgress";
import LoadingButton from "@mui/lab/LoadingButton";
import {API_URL} from "../../../../config";

export const SettingsReset = (props: { token: string; suffix_url: string }) => {
    const [isLoading, setIsLoading] = useState(false);

    function postSettings() {
        setIsLoading(true);
        fetch(`${API_URL}/ui-settings/${props.suffix_url}`, {
            method: "POST",
            headers: {Authorization: props.token},
            credentials: "include",
        })
            .then(r => r.json())
            .catch(console.error)
            .finally(() => {
                setIsLoading(false);
                document.location.reload();
            });
    }

    return (
        <div className={"UISettingsContainer"}>
            <LoadingButton
                loading={isLoading}
                loadingPosition={"center"}
                onClick={postSettings}
                style={{width: 90, margin: "0 auto", marginTop: "10%"}}
                loadingIndicator={
                    <CircularProgress style={{color: "white"}} size={16}/>
                }
                variant={"contained"}
            >
                Reset
            </LoadingButton>
        </div>
    );
};
