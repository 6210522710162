import React, {useEffect, useState} from "react";
import {Queue} from "../../types";
import {API_URL} from "../../config";
import {SVQueueSelect} from "./queues";
import {SVContent} from "./content";
import Stack from "@mui/material/Stack";
import {Typography} from "@mui/material";
import {Application} from "../../components/Application";

const SchemaVersioning = (props: { token: string }) => {
    const [queue, setQueue] = useState<Queue | null>(null);
    const [checked, setChecked] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [history, setHistory] = useState([]);
    const [versions, setVersions] = useState([]);
    const [schema, setSchema] = useState(null);
    const [schemaDiff, setSchemaDiff] = useState(false);
    const [schemaA, setSchemaA] = useState("");
    const [schemaB, setSchemaB] = useState("");
    let apiUrl = `${API_URL}/schema-versioning`;

    const handleJsonSwitchChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setChecked(event.target.checked);
    };

    useEffect(() => {
        if (versions.length === 1) {
            setSchemaDiff(false);
        } else if (versions.length === 2) {
            setSchemaDiff(true);
        } else if (versions.length === 0) {
            setSchemaDiff(false);
            setSchema(null);
            setSchemaA("");
            setSchemaB("");
        }
    }, [versions]);

    // Get a history for selected queue
    useEffect(() => {
        if (queue !== null) {
            setLoading(true);
            fetch(`${apiUrl}/history?queue=${encodeURIComponent(queue.id)}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: props.token,
                },
                credentials: "include",
            })
                .then(response => response.json())
                .then(response => {
                    if (response.success) {
                        setHistory(response.results);
                    } else {
                        setError(response.message);
                    }
                })
                .catch(console.error)
                .finally(() => setLoading(false));
        }
    }, [queue, apiUrl, props.token]);

    // Get a JSON content of selected schema
    useEffect(() => {
        setSchema(null);
        if (versions.length === 1) {
            setLoading(true);
            fetch(`${apiUrl}/content?version_id=${encodeURIComponent(versions[0])}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: props.token,
                },
                credentials: "include",
            })
                .then(response => response.json())
                .then(response => {
                    if (response.success) {
                        setSchema(response.results);
                    } else {
                        if (response.message !== "No schema has been specified") {
                            setError(response.message);
                        }
                    }
                })
                .catch(console.error)
                .finally(() => setLoading(false));
        } else if (versions.length === 2 && !schemaDiff) {
            setSchema(null);
            setLoading(true);
            fetch(
                `${apiUrl}/diff?schema_a=${encodeURIComponent(
                    versions[0]
                )}&schema_b=${encodeURIComponent(versions[1])}`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: props.token,
                    },
                    credentials: "include",
                }
            )
                .then(response => response.json())
                .then(response => {
                    if (response.success) {
                        let schema_a = JSON.stringify(
                            JSON.parse(response.results.a),
                            undefined,
                            2
                        );
                        let schema_b = JSON.stringify(
                            JSON.parse(response.results.b),
                            undefined,
                            2
                        );
                        setSchemaA(schema_a);
                        setSchemaB(schema_b);
                        setSchemaDiff(true);
                    } else if (response.message !== "No schema has been specified") {
                        setError(response.message);
                    }
                })
                .catch(console.error)
                .finally(() => setLoading(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [versions, queue, apiUrl, props.token]);

    return (
        <Application>
            <Stack spacing={2}>
                <Typography variant={"h3"}>Schema versioning</Typography>
                <Typography variant={"body2"} width={600} textAlign={"justify"}>
                    The Schema versioning is a tool that helps you check customer schemas
                    quickly and easily. You can use it to see different versions of the
                    schema and compare them. It only shows versions that have been made
                    since the last midnight.
                </Typography>
            </Stack>
            <SVQueueSelect
                token={props.token}
                queue={setQueue}
                currentQueue={queue}
            />
            <SVContent
                error={error}
                queue={queue}
                history={history}
                timestamps={setVersions}
                schema={schema}
                checked={checked}
                onChange={handleJsonSwitchChange}
                loading={loading}
                schemaDiff={schemaDiff}
                schemaA={schemaA}
                schemaB={schemaB}
            />
        </Application>
    );
};

export default SchemaVersioning;
