import Select, {SingleValue} from "react-select";
import React, {useEffect, useRef, useState} from "react";
import {Field, Queue} from "../../types";
import {API_URL} from "../../config";
import {filter, find, flatten, last, split, toNumber, uniqBy} from "lodash";

type Option = {
    label: string;
    value: string;
};
type Props = {
    queues: Queue[];
    token: string;
    field: Field | null;
    setField: (f: Field | null) => void;
};

const queueToSchemaId = (queue: Queue) =>
    last(
        filter(split(queue.schema, "/"), (seg: string) => !isNaN(toNumber(seg)))
    );

const FieldPicker = ({token, field, setField, queues}: Props) => {
    const [fields, setFields] = useState<Field[]>([]);
    const ref = useRef<any>(null);

    useEffect(() => {
        const schemaIds = queues.map(queueToSchemaId);
        Promise.all(
            schemaIds.map(schemaId =>
                fetch(`${API_URL}/api/fields?schema=${schemaId}`, {
                    headers: {Authorization: token},
                    credentials: "include",
                })
                    .then(r => r.json())
                    .then(({results}) => results)
            )
        )
            .then(fieldSets => flatten(fieldSets))
            .then(_fields => uniqBy(_fields, "id"))
            .then(_fields => setFields(_fields));
    }, [queues, token]);

    useEffect(() => {
        if (!field && ref.current) ref.current.focus();
    }, [field]);

    return (
        <div className="Selection">
            <label>Select Field</label>
            <Select
                // @ts-ignore
                title="Schema field"
                // @ts-ignore
                options={fields.map(({id}) => ({
                    value: id,
                    label: id,
                }))}
                ref={ref}
                autoFocus
                defaultMenuIsOpen
                isSearchable
                styles={styles}
                placeholder="Schema field"
                // @ts-ignore
                onChange={(option: SingleValue<Option>) => {
                    // @ts-ignore
                    setField(find(fields, {id: option?.value}) || null);
                }}
                value={{value: field?.id, label: field?.id}}
            />
        </div>
    );
};

const styles = {
    control: (base: any) => ({...base, borderRadius: 50}),
    option: (base: any) => ({...base, color: "black"}),
};

export default FieldPicker;
