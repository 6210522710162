import {useEffect, useState} from "react";
import {API_URL} from "../../../config";

interface FontOptions {
    fonts: string[]
    loading: boolean
}

interface DefaultFont {
    font: string
    loading: boolean
}

export const useFonts = (token: string) => {
    const [fonts, setFonts] = useState<string[]>([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)
        fetch(`${API_URL}/ui-settings/fonts`, {
            method: "GET", headers: {"Authorization": token}, credentials: "include",
        })
            .then(r => r.json())
            .then(data => {
                if (data.success) {
                    setFonts(data.results)
                }
            })
            .catch(err => new Error(err))
            .finally(() => setLoading(false))
    }, [token])

    return {fonts: fonts, loading: loading} as FontOptions
}

export const useDefaultFont = (token: string) => {
    const [font, setFont] = useState("")
    const [loading, setLoading] = useState(false)

    useEffect(
        () => {
            setLoading(true)
            fetch(`${API_URL}/ui-settings/font`, {
                method: 'GET', headers: {"Authorization": token}, credentials: "include"
            })
                .then(r => r.json())
                .then(data => {
                    if (data.success) {
                        setFont(data.results)
                    }
                })
                .catch(err => new Error(err))
                .finally(() => setLoading(false))
        }, [token])

    return {font: font, loading: loading} as DefaultFont
}

export const useSetFont = (token: string, font: string) => {
    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false)

    useEffect(() => {
        setLoading(true)
        fetch(`${API_URL}/ui-settings/font`, {method: 'POST', headers: {Authorization: token}, credentials: "include"})
            .then(r => r.json())
            .then(data => {
                if (data.success) {
                    setSuccess(true)
                } else {
                    setSuccess(false)
                }
            })
            .catch(err => new Error(err))
            .finally(() => setLoading(false))
    }, [token])

    if (!success) {
        throw new Error("Cannot update the font")
    }

    return loading
}
