import React, {useEffect, useState} from "react";
import {AppProps} from "../types";
import {Field, Queue} from "../../types";
import "../styles.css";
import {SingleQueue as QueuePicker} from "../../components/QueuePicker";
import FieldPicker from "../../components/FieldPicker";
import Histogram from "./components/Histogram";
import {Application} from "../../components/Application";

const FieldHistogram = ({token}: AppProps) => {
    const [queue, setQueue] = useState<Queue | null>(null);
    const [field, setField] = useState<Field | null>(null);

    useEffect(() => {
        setField(null);
    }, [queue]);

    return (
        <Application>
            <h1>Field Histogram</h1>
            <QueuePicker token={token} setQueue={setQueue} currentQueue={queue}/>
            {queue && (
                <FieldPicker
                    queues={[queue]}
                    token={token}
                    field={field}
                    setField={setField}
                />
            )}
            {field && queue && (
                <Histogram
                    token={token}
                    clearField={() => {
                        setField(null);
                    }}
                    queue={queue}
                    field={field}
                />
            )}
        </Application>
    );
};

export default FieldHistogram;
