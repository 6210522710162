export function generateRandomHexColor(): string {
    /**
     * Generate a random HEX color
     * @returns HEX color
     */
    return (
        "#" + (0x1000000 + Math.random() * 0xffffff).toString(16).substring(1, 6)
    );
}

export function sortByKey(array: any[], key: string) {
    /**
     * Sort any array of objects by the key in it
     * @param: Array to sort
     * @key: By which key to sort the array
     * @return Sorted array of objects by the key
     */
    return array.sort(function (a, b) {
        let x = a[key];
        let y = b[key];
        return x < y ? -1 : x > y ? 1 : 0;
    });
}

export function linSpaceArray(beginning: number, end: number): number[] {
    const array: number[] = [];
    for (let i = beginning; i < end; i++) {
        array.push(i);
    }
    return array;
}
