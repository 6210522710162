import {createTheme} from "@mui/material/styles";

export function nameInitials(name: string): string {
    const split = name.split(" ");
    return (
        split[0].slice(0, 1).toUpperCase() + split[1].slice(0, 1).toUpperCase()
    );
}

export const theme = createTheme({
    palette: {
        mode: "dark",
    },
});
