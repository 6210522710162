import {ChartDataInterface} from "../../interfaces/results";
import React from "react";
import {generateRandomHexColor, linSpaceArray, sortByKey} from "./helpers";
import Plot from "react-plotly.js";
import {selectData} from "./controller";

export const AccuracyChart = ({
                                  data,
                                  agenda,
                                  source,
                                  scale,
                              }: ChartDataInterface) => {
    /**
     * Component of the AccuracyChart with result data
     * @param data: Data from API
     * @param agenda: Agenda name
     * @param source: Which data to use (Header fields or Line items)
     * @param ChartScaler: How to ChartScaler marker size relative to the
     *  annotations number size
     * @returns A AccuracyChart visualization of the API data
     * for selection (Header fields or Line items)
     */
    const resources = selectData(data, source);

    // @ts-ignore
    const uniqueFieldNames = [...new Set(resources.map(item => item.fieldName))];
    uniqueFieldNames.sort();

    const chartData = [];
    for (let i = 0; i < uniqueFieldNames.length; i++) {
        const fieldName = uniqueFieldNames[i];
        const filtered = resources.filter(item => {
            return item.fieldName === fieldName;
        });
        const sorted = sortByKey(filtered, "training_id");
        const accWithoutExtra: number[] = [];
        const annotationsNumber: number[] = [];
        const trainingId: number[] = [];
        for (let i = 1; i < sorted.length; i++) {
            accWithoutExtra.push(sorted[i].accWithoutExtra * 100);
            annotationsNumber.push(sorted[i].annotationsNumber);
            trainingId.push(sorted[i].trainingId);
        }

        const trainingIndexes = linSpaceArray(0, trainingId.length);
        const uniqueTrainingsNames = [
            // @ts-ignore
            ...new Set(resources.map(item => item.trainingName)),
        ];
        const uniqueTrainingsIds = [
            // @ts-ignore
            ...new Set(resources.map(item => item.trainingId)),
        ];

        const trainingLabels: string[] = [];
        for (let j = 0; j < trainingId.length; j++) {
            const id = trainingId[j];
            if (uniqueTrainingsIds.includes(id)) {
                const index = uniqueTrainingsIds.indexOf(id);
                trainingLabels.push(uniqueTrainingsNames[index]);
            }
        }

        chartData.push({
            x: trainingIndexes,
            y: accWithoutExtra,
            name: fieldName,
            type: "scatter",
            mode: "markers",
            text: trainingLabels,
            marker: {
                color: generateRandomHexColor(),
                size: annotationsNumber,
                sizeref: scale,
                sizemin: 2,
            },
            hovertemplate:
                "<b>Training %{text}</b><br><br>Field name: %{data.name}<br>Accruacy without " +
                "extra: %{y:.2f} %<br>Annotations number: %{marker.size:,}<extra></extra>",
        });
    }

    return (
        <Plot
            // @ts-ignore
            data={chartData}
            config={{
                toImageButtonOptions: {
                    format: "svg", // one of png, svg, jpeg, webp
                    filename: `Accuracies Report ${agenda}`,
                    width: 800,
                    height: 600,
                    scale: 1, // multiply title/legend/axis/canvas sizes by this factor
                },
                scrollZoom: true,
                displaylogo: false,
            }}
            layout={{
                width: 600,
                height: 400,
                title: `Accuracies Report for agenda ${agenda}`,
                showlegend: true,
                xaxis: {
                    title: "Training indexes",
                    type: "linear",
                },
                yaxis: {
                    title: "Accuracy without extra (%)",
                    type: "linear",
                    rangemode: "tozero",
                },
                legend: {itemsizing: "constant"},
            }}
        />
    );
};
