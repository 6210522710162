import {API_URL} from "../../config";

export const getAAssistants = (token: string) =>
    fetch(`${API_URL}/api/annotation-assistant/hooks`, {
        method: "GET",
        headers: {Authorization: `Token ${token}`},
        credentials: "include",
    })
        .then(r => r.json())
        .catch(console.error);

export const addAAssistant = (queueId: number, token: string) =>
    fetch(`${API_URL}/api/annotation-assistant/hooks`, {
        method: "POST",
        headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            queue_id: String(queueId),
            configuration: {},
        }),
        credentials: "include",
    })
        .then(r => r.json())
        .catch(console.error);

export const removeAAssistant = (queueId: number, token: string) =>
    fetch(`${API_URL}/api/annotation-assistant/hooks/${queueId}`, {
        method: "DELETE",
        headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
        },
        credentials: "include",
    })
        .then(r => r.json())
        .catch(console.error);
