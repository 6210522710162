import {Queue} from "../types";
import {useEffect, useState} from "react";
import {API_URL} from "../config";

export const useQueuesList = (token: string) => {
    const [queues, setQueues] = useState<Queue[]>([]);

    useEffect(() => {
        fetch(`${API_URL}/api/rossum/queues`, {
            headers: {Authorization: token},
            credentials: "include",
        })
            .then(r => r.json())
            .then(response => setQueues(response.results))
            .catch(console.error);
    }, [token]);

    return queues;
};
