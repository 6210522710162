import React, {Dispatch} from "react";
import {FormControl, FormControlLabel, Radio, RadioGroup,} from "@mui/material";
import {FieldType} from "../../interfaces/source";

interface SourceInterface {
    source: FieldType;
    setSource: Dispatch<FieldType>;
}

export const SourceSelector = ({source, setSource}: SourceInterface) => {
    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        if (event.target.value === "fields") {
            setSource(FieldType.HeaderField);
        } else {
            setSource(FieldType.LineItem);
        }
    }

    return (
        <FormControl>
            <RadioGroup
                row
                aria-labelledby="chart-data-source"
                value={source === FieldType.HeaderField ? "fields" : "tables"}
                name="radio-buttons-group"
                onChange={handleChange}
            >
                <FormControlLabel
                    value="fields"
                    control={<Radio/>}
                    label="Header Fields"
                />
                <FormControlLabel
                    value="tables"
                    control={<Radio/>}
                    label="Line Items"
                />
            </RadioGroup>
        </FormControl>
    );
};
