import {Tooltip, Typography} from "@mui/material";
import React from "react";
import Stack from "@mui/material/Stack";

export const AccuracyHeader = () => {
    return (
        <Stack spacing={2}>
            <Typography variant={"h3"}>Accuracy report</Typography>
            <Typography variant={"body2"} width={600} textAlign={"justify"}>
                Visualize the number of annotations in dataset against field accuracy
                without extra. Please, start with selecting the customer and then you
                can inspect the interactive chart.
            </Typography>
            <Tooltip
                title={
                    <React.Fragment>
                        <Stack
                            direction={"column"}
                            textAlign={"justify"}
                            spacing={2}
                            margin={2}
                        >
                            <Typography variant={"caption"}>
                                Select source data by switching radio button to either{" "}
                                <strong>header fields or line items</strong>.
                            </Typography>
                            <Typography variant={"caption"}>
                                If the marker size is too large on the plot, please use the
                                <strong>size slider</strong> to scale the marker size to your
                                liking.
                            </Typography>
                            <Typography variant={"caption"}>
                                To select a specific fields in the chart, use a legend. It is a
                                interactive one. By <strong>double-clicking</strong> only the
                                selected field is presented. Then when a{" "}
                                <strong>single-click</strong> is performed to another field, it
                                is automatically added to the selection. Once all fields are
                                presented in the chart, a single-click removes the selected
                                field from the chart.
                            </Typography>
                            <Typography variant={"caption"}>
                                Chart has some other useful tools in its top right corner. The
                                first one is a button that can{" "}
                                <strong>export the chart to SVG</strong> (a camera button).
                            </Typography>
                        </Stack>
                    </React.Fragment>
                }
            >
                <Typography
                    sx={{
                        textDecoration: "underline",
                        textDecorationStyle: "dotted",
                        cursor: "pointer",
                    }}
                    variant={"body1"}
                >
                    How to use interactive chart?
                </Typography>
            </Tooltip>
        </Stack>
    );
};
