import {API_URL} from "../../../config";
import {AccuracyReportApiInterface} from "../interfaces/data";

export async function getAccuracyReport(
    token: string,
    agenda: string
): Promise<AccuracyReportApiInterface> {
    let response = await fetch(
        `${API_URL}/accuracy-report/${agenda}`,
        {
            method: "GET",
            credentials: "include",
        }
    );
    return await response.json();
}
