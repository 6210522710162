import {useEffect, useState} from "react";
import {API_URL} from "../../../config";

interface ApiResponse {
    success: Boolean
    is_support_access: Boolean
}


export const useGetSupportAccess = (token: string): Boolean => {
    const [isSupportAccess, setIsSupportAccess] = useState<boolean>(false)

    async function getSupportAccess() {
        const apiResponse = await fetch(`${API_URL}/api/rossum/is-support-access`, {
            method: 'GET',
            headers: {Authorization: token},
            credentials: "include",
        });
        const {is_support_access, success} = await apiResponse.json() as ApiResponse;
        if (success) {
            setIsSupportAccess(Boolean(is_support_access))
        }
    }

    useEffect(() => {
        getSupportAccess().then()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return isSupportAccess
}
