import "../styles.css";
import Stack from "@mui/material/Stack";
import {Alert, AlertTitle, Grid, Typography} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import {UseStateEnabled} from "./hooks/UseStateEnabled";
import {API_URL} from "../../config";
import Chip from "@mui/material/Chip";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import LockIcon from "@mui/icons-material/Lock";
import {AppProps} from "../types";
import {ConfirmDialog} from "./components/ConfirmDialog";
import {UseOrganizationID} from "./hooks/UseOrganizationID";
import moment from "moment/moment";
import {useGetSupportAccess} from "./hooks/useGetSupportAccess";
import {Application} from "../../components/Application";

export interface LockedOrganizationInt {
    id: number;
    changed_by: string[];
    date_changed: string;
}

export interface LockedOrganizationResultInt {
    results: LockedOrganizationInt[];
}

const DataCaptureLock = ({token, username}: AppProps) => {
    const [error, setError] = useState<string[] | null>(null);
    const [isEnabled, setEnabled] = useState(true);
    const originalEnabled = isEnabled;
    const [isLoading, setIsLoading] = useState(false);
    const [isReady, setIsReady] = useState(false);
    const [lockedOrganizations, setLockedOrganizations] =
        useState<LockedOrganizationResultInt | null>(null);
    const isEnabledStatus = UseStateEnabled(token, setError);

    const [open, setOpen] = React.useState(false);
    const [confirmed, setConfirmed] = React.useState(false);
    const [confirmationID, setConfirmationID] = React.useState<number | null>(
        null
    );
    const [confirmationError, setConfirmationError] = useState<string | null>(
        null
    );

    const organizationID = UseOrganizationID(token, username);
    const isSupportAccess = useGetSupportAccess(token)

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleConfirm = () => {
        const isValid = confirmationID === organizationID;
        if (isValid) {
            setConfirmed(true);
            setOpen(false);
        } else {
            if (!confirmationID) {
                setConfirmationError(
                    `Organization ID is not set. Enter ${organizationID} and try again.`
                );
            } else {
                setConfirmationError(
                    `Organization ID ${organizationID} does not match entered ID ${confirmationID}. Please, enter a correct one (${organizationID}) and try again.`
                );
            }
        }
    };

    const handleCancel = () => {
        setConfirmed(false);
        setOpen(false);
    };

    useEffect(() => {
        fetch(`${API_URL}/data-capture-lock/organizations`, {
            method: "GET",
            headers: {Authorization: token},
            credentials: "include",
        })
            .then(r => r.json())
            .then(response => {
                if (response.success) {
                    setLockedOrganizations(response);
                } else {
                    setError([response.message, response.detail]);
                }
            })
            .catch(err => {
                setError(["Error while getting all locked organizations", err]);
            });
    }, [token, isLoading]);

    useEffect(() => {
        if (isEnabledStatus !== null) {
            setEnabled(isEnabledStatus);
            setIsReady(true);
            setIsLoading(false);
        } else {
            setIsLoading(true);
        }
    }, [isEnabledStatus]);

    useEffect(() => {
        if (confirmed) {
            setOpen(false);
            setConfirmed(false);
            onChange();
        }
    }, [confirmed, onChange]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    function onChange() {
        setIsLoading(true);
        setEnabled(!isEnabled);
        fetch(`${API_URL}/data-capture-lock/`, {
            method: "PATCH",
            body: JSON.stringify({enabled: !isEnabled}),
            headers: {Authorization: token},
            credentials: "include",
        })
            .then(r => r.json())
            .then(response => {
                if (!response.success) {
                    setError([response.message, response.detail]);
                    setEnabled(originalEnabled);
                }
            })
            .catch(err => {
                setError(["Error while calling the API for locked status", err]);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    return (
        <Application>
            <Typography variant={"h3"} marginBottom={2}>
                Data Capture Lock
            </Typography>
            <Stack
                alignItems={"center"}
                style={{
                    background: "#25272b",
                    borderRadius: 25,
                    paddingTop: 20,
                    paddingLeft: 2,
                    paddingRight: 2,
                    paddingBottom: 20,
                }}
                width={800}
            >
                <ConfirmDialog
                    open={open}
                    onClose={handleClose}
                    enabled={isEnabled}
                    handleCancel={handleCancel}
                    handleConfirm={handleConfirm}
                    orgName={username}
                    orgID={organizationID}
                    setConfirmationID={setConfirmationID}
                    error={confirmationError}
                />
                <Typography variant={"caption"}>
                    The utility for disabling or enabling the data capture for all
                    available users in the current organization.
                </Typography>
                {!isSupportAccess && (
                    <Alert severity={"error"} style={{margin: 5}}>
                        <Typography variant={"body2"}>
                            User have to be logged from{" "}
                            <a href={"https://elis.rossum.ai/api/c483f/"}>Rossum Admin</a>
                        </Typography>
                        <Typography variant={"caption"}>
                            Disabling the organization from the user account could lead to a
                            data loss while changing the user and queue rules. Please, click
                            on MAPRD button in Elis Admin and then try again!
                        </Typography>
                    </Alert>
                )}
                {error && (
                    <Alert severity={"error"} style={{margin: 10, textAlign: "left"}}>
                        <AlertTitle>
                            <strong>{error[0]}</strong>
                        </AlertTitle>
                        {error[1]}
                    </Alert>
                )}
                {isReady && !isLoading && isSupportAccess && (
                    <Stack
                        direction={"row"}
                        justifyContent={"center"}
                        alignContent={"center"}
                        alignItems={"center"}
                    >
                        <FormControlLabel
                            onChange={() => handleClickOpen()}
                            control={<Switch checked={isEnabled} disabled={isLoading}/>}
                            label={
                                <Typography variant={"h6"}>
                                    {isEnabled ? "Data Capture Enabled" : "Data Capture Disabled"}
                                </Typography>
                            }
                        />
                        {isEnabled && <LockOpenIcon/>}
                        {!isEnabled && <LockIcon/>}
                    </Stack>
                )}
                {!isLoading && (
                    <Typography variant={"caption"} color={isEnabled ? "green" : "red"}>
                        This organization is set to{" "}
                        {isEnabled ? "accept all" : "block all new"} data capture
                    </Typography>
                )}
                {isLoading && (
                    <Box sx={{display: "flex", marginTop: 2.5}}>
                        <CircularProgress/>
                    </Box>
                )}
            </Stack>
            <Stack
                direction={"column"}
                alignItems={"center"}
                style={{background: "#25272b", borderRadius: 25}}
                marginTop={2}
                paddingLeft={2}
                paddingRight={2}
                paddingBottom={5}
                marginBottom={10}
                width={800}
            >
                <Typography variant={"h5"} marginTop={5}>
                    Locked organizations
                </Typography>
                <Typography variant={"caption"} textAlign={"left"} margin={2}>
                    Following is a list of all organizations for which the Data Capture
                    Lock application has been disabled. All users in such an organization
                    are viewers. Therefore, they can view their documents but cannot
                    process a new one or create another user.
                </Typography>
                <Grid
                    container
                    alignItems={"flex-start"}
                    flex={"fit-content"}
                    justifyContent={"center"}
                >
                    {lockedOrganizations &&
                        lockedOrganizations.results.map(function (value, index) {
                            return (
                                <Chip
                                    key={index}
                                    label={
                                        <Stack direction={"column"}>
                                            <div>
                                                Org ID <b>{value.id}</b>
                                            </div>
                                            <div>{moment(value.date_changed).fromNow()}</div>
                                        </Stack>
                                    }
                                    variant={"filled"}
                                    color={"error"}
                                    style={{margin: 5, height: 50}}
                                />
                            );
                        })}
                    {!lockedOrganizations?.results.length && (
                        <Typography variant={"body1"} color={"gray"}>
                            No disabled organizations
                        </Typography>
                    )}
                </Grid>
            </Stack>
        </Application>
    );
};
export default DataCaptureLock;
