import {Alert, Snackbar} from "@mui/material";
import React, {Dispatch} from "react";
import {ErrorInterface} from "../../interfaces/error";

interface AlertsInterface {
    error: ErrorInterface | null;
    setError: Dispatch<ErrorInterface | null>;
}

export const AlertSnackbar = ({error, setError}: AlertsInterface) => {
    function closeAlert() {
        setError(null);
    }

    return (
        <div>
            {error && (
                <Snackbar open={true} autoHideDuration={6000} onClose={closeAlert}>
                    <Alert key={error.message} severity={error.severity}>
                        <strong>{error.message}</strong> {error.detail}
                    </Alert>
                </Snackbar>
            )}
        </div>
    );
};
