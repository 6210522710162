import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import React, {Dispatch} from "react";
import {Agendas} from "../../interfaces/agendas";

interface AgendaSelectProps {
    agenda: string;
    agendas: Agendas;
    setAgenda: Dispatch<string>;
}

export const AgendaSelector = ({
                                   agenda,
                                   agendas,
                                   setAgenda,
                               }: AgendaSelectProps) => {
    return (
        <FormControl fullWidth>
            <InputLabel id="agenda-select-label">Agenda</InputLabel>
            <Select
                labelId="agenda-select-label"
                id="agendaSelectLabel"
                value={agenda}
                label="Agenda"
                onChange={e => setAgenda(e.target.value)}
                style={{textAlign: "left"}}
            >
                {Object.entries(agendas ? agendas : {}).map(([_, value]) => (
                    <MenuItem key={`${value.value}`} value={value.value}>
                        {value.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};
