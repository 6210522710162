import React, {Dispatch} from "react";
import {ZoomIn, ZoomOut} from "@mui/icons-material";
import {Slider} from "@mui/material";
import Stack from "@mui/material/Stack";

export const ChartScaler = (props: {
    value: number;
    setValue: Dispatch<number>;
}) => {
    const handleChange = (event: Event, newValue: number | number[]) => {
        props.setValue(Number(newValue));
    };

    return (
        <Stack
            spacing={2}
            direction="row"
            sx={{mb: 1}}
            alignItems="center"
            width={310}
        >
            <ZoomIn
                onClick={() => props.setValue(props.value - 10)}
                style={{cursor: "pointer"}}
            />
            <Slider
                aria-label="scale-level"
                value={props.value}
                onChange={handleChange}
                max={1000}
                min={20}
                step={1}
            />
            <ZoomOut
                onClick={() => props.setValue(props.value + 10)}
                style={{cursor: "pointer"}}
            />
        </Stack>
    );
};
