import {useOrganizationSettings} from "../../hooks/hooks";
import React, {Dispatch, useEffect, useState} from "react";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import {FormControl, Radio, RadioGroup} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import {API_URL} from "../../../../config";

function patchTheme(
    theme: string,
    token: string,
    setIsLoading: Dispatch<boolean>
) {
    if (theme) {
        setIsLoading(true);
        fetch(`${API_URL}/ui-settings/theme/${encodeURIComponent(theme)}`, {
            method: "POST",
            headers: {Authorization: token},
            credentials: "include",
        })
            .then(r => r.json())
            .catch(console.error)
            .finally(() => {
                setIsLoading(false);
            });
    }
}

interface IThemeSelection {
    token: string;
    theme: string | null;
    setSettings: Dispatch<string | null>;
    setIsLoading: Dispatch<boolean>;
}

const ThemeSelectionRadioButtons = ({
                                        theme,
                                        setSettings,
                                        token,
                                        setIsLoading,
                                    }: IThemeSelection) => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSettings((event.target as HTMLInputElement).value);
        setHasChanged(true);
    };
    const [hasChanged, setHasChanged] = useState(false);

    useEffect(() => {
        if (theme && hasChanged) {
            patchTheme(theme, token, setIsLoading);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [theme]);

    return (
        <FormControl>
            <RadioGroup
                aria-labelledby="theme-selection-radio-buttons"
                name="theme-selection"
                value={theme}
                onChange={handleChange}
            >
                <FormControlLabel value="none" control={<Radio/>} label="None"/>
                <FormControlLabel value="black" control={<Radio/>} label="Black"/>
                <FormControlLabel value="white" control={<Radio/>} label="White"/>
            </RadioGroup>
        </FormControl>
    );
};

export const SettingsTheme = (props: { token: string; suffix_url: string }) => {
    const defaultSettings = useOrganizationSettings(
        props.token,
        props.suffix_url
    );
    const [settings, setSettings] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (defaultSettings) {
            setSettings(defaultSettings);
        }
    }, [defaultSettings]);

    return (
        <React.Fragment>
            <div className={"UISettingsContainer"}>
                <Stack>
                    <label style={{fontSize: "small"}}>Theme</label>
                    {defaultSettings && !isLoading && (
                        <React.Fragment>
                            <ThemeSelectionRadioButtons
                                token={props.token}
                                theme={settings}
                                setSettings={setSettings}
                                setIsLoading={setIsLoading}
                            />
                        </React.Fragment>
                    )}
                    {!defaultSettings && (
                        <CircularProgress style={{margin: "0 auto", marginTop: 35}}/>
                    )}
                    {isLoading && (
                        <CircularProgress style={{margin: "0 auto", marginTop: 35}}/>
                    )}
                </Stack>
            </div>
        </React.Fragment>
    );
};
