import React, {Dispatch, useEffect, useState} from "react";
import {AppProps} from "../types";
import {Application} from "../../components/Application";
import {Typography} from "@mui/material";
import {Queue} from "../../types";
import {useQueuesList} from "../../hooks/queues";
import Stack from "@mui/material/Stack";
import {CircularProgress} from "@material-ui/core";
import {BiCheck, BiLoaderAlt, BiX} from "react-icons/bi";
import "./styles.css"
import {API_URL} from "../../config";

interface QueueFilterI {
    queues: Queue[]
    setQueues: Dispatch<Queue[]>
    allQueues: Queue[]
    isLoading: boolean
    initialized: boolean
}

const QueueFilter = ({queues, setQueues, allQueues, isLoading, initialized}: QueueFilterI) => {
    const toggleQueue = (id: number) => {
        if (queues.some(queue => queue.id === id)) {
            setQueues(queues.filter(queue => queue.id !== id));
        } else {
            // @ts-ignore
            setQueues([...queues, allQueues.find(queue => queue.id === id)]);
        }
    };

    return <>
        {initialized ?
            <>
                {allQueues.map(({name, id}, i) => {
                    const selected = queues.some(queue => queue.id === id)
                    const Icon = selected ? BiCheck : BiX;
                    const backgroundColor = selected ? "#1b1922" : "red"

                    return <button
                        key={id}
                        autoFocus={i === 0}
                        className={"AA-Row"}
                        style={{width: "80%", backgroundColor: backgroundColor}}
                        onClick={() => toggleQueue(id)}
                        disabled={isLoading}
                    >
                        <div className={"AA-Row-Name"}>
                            {name} ({id})
                        </div>
                        <Icon size={22}/>
                    </button>
                })}
            </>
            :
            <Stack alignItems={'center'} marginTop={5}>
                <CircularProgress size={28}/>
                <Typography variant={"h5"}>Loading queues...</Typography>
            </Stack>
        }
    </>
}

const SchemaBreakdown = ({token, username, deselectApp}: AppProps) => {
        const [isLoading, setIsLoading] = useState(false); // Initialize isLoading state

        const allQueues = useQueuesList(token)
        const [queues, setQueues] = useState<Queue[]>([])
        const [initialized, setInitialized] = useState(false)

        useEffect(() => {
            if (allQueues.length > 0) {
                setQueues(allQueues)
                setInitialized(true)
            }
        }, [allQueues]);


        const makeRequest = () => {
            setIsLoading(true)

            // Extract the selected queue IDs

            const selectedQueueIds = queues.map(queue => queue.id);

            // Construct the URL with the selected queue IDs as a parameter
            const queryParams = new URLSearchParams({
                queue_filter: selectedQueueIds.join(",")
            });

            const url = `${API_URL}/api/schema-breakdown?${queryParams}`;

            fetch(url, {
                credentials: "include", headers: {"Authorization": token, Accept: "text/csv"}
            })
                .then(r => r.blob())
                .then(b => URL.createObjectURL(b))
                .then(url => {
                    const a = document.createElement("a");
                    a.href = url;
                    a.download = `${username}_breakdown.csv`;
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                    URL.revokeObjectURL(url);
                })
                .catch(console.error)
                .finally(() => {
                    deselectApp()
                    setIsLoading(false)
                });
        }

        const deselectAll = () => {
            setQueues([])
        }

        const selectAll = () => {
            setQueues(allQueues)
        }

        const invertSelection = () => {
            const queueIdsToExclude = new Set(queues.map(queue => queue.id)); // Assuming each Queue has an 'id' property
            const invertedQueues = allQueues.filter(queue => !queueIdsToExclude.has(queue.id));
            setQueues(invertedQueues);
        }

        return <>
            <Application>
                <Typography variant={"h3"}>Schema Breakdown</Typography>
                <Typography variant={"caption"} width={600}>
                    A helper tool which can help user to understand the field names (labels and
                    rir_field_names) across different queues. Since some organizations may
                    have a big number of queues, user can filter out by deselecting the queues
                    from the schema breakdown report in CSV table. To get the full report, please
                    click on Download button in the bottom right corner. To deselect, please click
                    on the each queue you want to remove from the report.
                </Typography>
                <QueueFilter allQueues={allQueues} queues={queues} setQueues={setQueues} isLoading={isLoading}
                             initialized={initialized}/>
                {allQueues.length > 0 &&
                    <div className={"FixedForm"} style={{width: 170}}>
                        <Typography variant={"body2"}>{queues.length} queues selected</Typography>
                        <Typography variant={"body2"}>{allQueues.length - queues.length} queues deselected</Typography>
                        <button onClick={deselectAll} className={"Button"} style={{width: '100%'}}>Deselect all</button>
                        <button onClick={selectAll} className={"Button"} style={{width: '100%'}}>Select all</button>
                        <button onClick={invertSelection} className={"Button"} style={{width: '100%'}}>Invert</button>
                        <button className={`Button ${isLoading ? "Loading" : ""}`}
                                onClick={makeRequest} disabled={isLoading} style={{width: '100%', marginTop: 30}}>
                            {isLoading ? <BiLoaderAlt className="Loader"/> : "Download"}
                        </button>
                    </div>
                }
            </Application>
        </>
    }
;

export default SchemaBreakdown;
