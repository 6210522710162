import {includes, map, toLower} from "lodash";
import React from "react";

type Props = {
    appFilter: string;
    appName: string;
    rowIndex: number;
    columnIndex: number;
    index: [number, number];
    onAppChange: (appName: string) => void;
    setIndex: (index: [number, number]) => void;
};

const AppName = ({
                     appFilter,
                     appName,
                     rowIndex,
                     columnIndex,
                     index,
                     onAppChange,
                     setIndex,
                 }: Props) => {
    const searches = appFilter.split(" ");
    const segments = appName.split(new RegExp(`(${searches.join("|")})`, "ig"));
    return (
        <div
            style={{
                textDecoration:
                    rowIndex === index[0] && columnIndex === index[1]
                        ? "underline"
                        : "none",
            }}
            className="AppName"
            key={appName}
            onMouseEnter={() => setIndex([rowIndex, columnIndex])}
            onClick={() => onAppChange(appName)}
        >
            {map(segments, (seg, i) =>
                    includes(searches.map(toLower), toLower(seg)) ? (
                        <span key={String(i)} className="Bold">
            {seg}
          </span>
                    ) : (
                        seg
                    )
            )}
        </div>
    );
};

export default AppName;
