import LoadingButton from "@mui/lab/LoadingButton";
import React from "react";

export const OrganizationButton = (props: { token: string }) => {
    function organizationInNewTab() {
        window.open(`https://elis.rossum.ai#authToken=${props.token}`, "_blank");
    }

    return (
        <div className={"UISettingsContainer"}>
            <LoadingButton
                onClick={organizationInNewTab}
                style={{width: 90, margin: "0 auto", marginTop: "10%"}}
                variant={"contained"}
            >
                Open
            </LoadingButton>
        </div>
    );
};
