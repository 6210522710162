import {useDefaultFont, useFonts} from "../../hooks/fonts";
import CircularProgress from "@mui/material/CircularProgress";
import React, {useEffect, useState} from "react";
import {FormControl, InputLabel, MenuItem, Select, SelectChangeEvent} from "@mui/material";
import Stack from "@mui/material/Stack";
import {API_URL} from "../../../../config";

interface Props {
    token: string
}

export const SettingsFont = ({token}: Props) => {
    const options = useFonts(token)
    const [font, setFont] = useState("")
    const currentlyUsedFont = useDefaultFont(token)

    const [changeLoading, setChangeLoading] = useState(false)

    useEffect(() => {
        if (font.length < 1) {
            return
        }
        if (font !== currentlyUsedFont.font) {
            updateFont(token, font)
        }
    }, [currentlyUsedFont.font, font, token])

    useEffect(() => {
        if (options.fonts.includes(currentlyUsedFont.font)) {
            setFont(currentlyUsedFont.font)
        }
    }, [options.fonts, currentlyUsedFont.font])

    function updateFont(token: string, font: string): boolean {
        let success = false
        setChangeLoading(true)
        fetch(`${API_URL}/ui-settings/font`, {
            method: 'POST',
            headers: {Authorization: token, "Content-Type": "application/json"},
            credentials: "include",
            body: JSON.stringify({'font': font})
        })
            .then(r => r.json())
            .then(data => {
                success = data.success
            })
            .catch(err => new Error(err))
            .finally(() => setChangeLoading(false))
        return success
    }

    const handleChange = (event: SelectChangeEvent) => {
        setFont(event.target.value as string);
    };

    return (
        <Stack width={400} alignItems={'center'}>
            <label style={{fontSize: "small"}}>Font</label>
            {options.loading && <CircularProgress/>}
            {!options.loading && options.fonts && <>
                <FormControl fullWidth>
                    <InputLabel id="select-font-label">Font</InputLabel>
                    <Select
                        labelId="select-font-label"
                        id="font-select"
                        value={font}
                        label="Font"
                        onChange={handleChange}
                        disabled={changeLoading}
                    >
                        {options.fonts.map((item, i) =>
                            <MenuItem value={item} key={i}>{item}</MenuItem>)
                        }
                    </Select>
                </FormControl>
            </>}
        </Stack>
    )
}
