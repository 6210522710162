import {AccuracyReportApiInterface} from "../../interfaces/data";
import {FieldType} from "../../interfaces/source";
import {Resources} from "../../interfaces/resources";

export function selectData(
    data: AccuracyReportApiInterface,
    source: FieldType
): Resources[] {
    /**
     * Select the data from the API based on the field type
     * @param source: Which field type to choose (Header fields or Line items)
     * @returns Selected data as ResourceData[]
     */
    const resources: Resources[] = [];
    for (let i = 0; i < data.results.length; i++) {
        const {fields, training_name, training_id} = data.results[i];
        const selection =
            source === FieldType.HeaderField
                ? fields.header_fields
                : fields.line_items;
        for (let j = 0; j < selection.length; j++) {
            const {name, acc_without_extra, annotations_number} = selection[j];
            resources.push({
                fieldName: name,
                trainingName: training_name,
                trainingId: training_id,
                accWithoutExtra: acc_without_extra,
                annotationsNumber: annotations_number,
            });
        }
    }
    return resources;
}
