import React from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import CodeEditor from "@uiw/react-textarea-code-editor";
import ReactDiffViewer from "react-diff-viewer";
import ReactJson from "react-json-view";

export class JsonFormatterSwitcher extends React.Component<{
    checked: boolean;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}> {
    /* A switch for FancyJsonViewer or RawJsonViewer */
    render() {
        return (
            <FormGroup>
                <FormControlLabel
                    control={
                        <Switch
                            checked={this.props.checked}
                            onChange={this.props.onChange}
                            inputProps={{"aria-label": "controlled"}}
                        />
                    }
                    label="Nice JSON"
                />
            </FormGroup>
        );
    }
}

export class FancyJsonViewer extends React.Component<{ text: any }> {
    render() {
        /* Renders a content of the schema as fancy JSON with
                the buttons and copy on click (and nice formatting) */
        return (
            // @ts-ignore
            <ReactJson
                src={JSON.parse(this.props.text)}
                style={{
                    fontSize: 14,
                    textAlign: "left",
                    minWidth: 600,
                    background: "#292C34",
                }}
                theme={"monokai"}
                displayDataTypes={false}
                iconStyle={"square"}
                quotesOnKeys={false}
                indentWidth={2}
                collapseStringsAfterLength={80}
            />
        );
    }
}

export class RawJsonViewer extends React.Component<{ text: any }> {
    render() {
        /* Renders a content of the schema as a raw JSON file
         * with just basic code formatting. */
        return (
            <div>
                <CodeEditor
                    value={String(
                        JSON.stringify(JSON.parse(this.props.text), undefined, 2)
                    )}
                    language="json"
                    // data-color-mode={'light'}
                    padding={15}
                    style={{
                        fontSize: 14,
                        backgroundColor: "#ffffff",
                        fontFamily:
                            "ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace",
                        borderRadius: 20,
                    }}
                    readOnly
                />
            </div>
        );
    }
}

export const JsonDiff = (props: { old: string; new: string }) => {
    return (
        <ReactDiffViewer
            oldValue={props.old}
            newValue={props.new}
            splitView={false}
            showDiffOnly={false}
            useDarkTheme={true}
            disableWordDiff={true}
            styles={{
                content: {fontSize: 12, textAlign: "left", width: 900},
                line: {fontSize: 12},
            }}
        />
    );
};
