import React, {EventHandler} from "react";
import {MdExitToApp} from "react-icons/md";

type Props = {
    username: string;
    onLogout: EventHandler<any>;
};

const OrgPanel = ({username, onLogout}: Props) => {
    return (
        <div className="OrgPanel" onClick={onLogout}>
            Organization: {username}
            <div className="OrgPanelLogout">
                <MdExitToApp/> Click to Logout
            </div>
        </div>
    );
};

export default OrgPanel;
