import React, {useState} from 'react';
import {Collapse, Link} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const TooltipDropdown: React.FC<{ label: string; children: React.ReactNode }> = ({label, children}) => {
    const [expanded, setExpanded] = useState(false);

    const handleToggle = () => {
        setExpanded((prevExpanded) => !prevExpanded);
    };

    return (
        <div>
            <Link onClick={handleToggle}
                  sx={{cursor: 'pointer', display: 'flex', alignItems: 'center', fontSize: '14px'}}>
                {label}
                <ExpandMoreIcon
                    sx={{ml: 1, transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)', transition: '0.3s'}}/>
            </Link>
            <Collapse in={expanded}>
                {children}
            </Collapse>
        </div>
    );
};

export default TooltipDropdown;
