import {useOrganizationSettings} from "../../hooks/hooks";
import React, {useState} from "react";
import {API_URL} from "../../../../config";
import Stack from "@mui/material/Stack";
import LoadingButton from "@mui/lab/LoadingButton";
import CircularProgress from "@mui/material/CircularProgress";

export const SettingsColor = (props: { token: string; suffix_url: string }) => {
    const defaultSettings = useOrganizationSettings(
        props.token,
        props.suffix_url
    );
    const [settings, setSettings] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(false);

    function postSettings() {
        if (settings) {
            setIsLoading(true);
            fetch(
                `${API_URL}/ui-settings/${props.suffix_url}/${encodeURIComponent(
                    settings
                )}`,
                {
                    method: "POST",
                    headers: {Authorization: props.token},
                    credentials: "include",
                }
            )
                .then(r => r.json())
                .catch(console.error)
                .finally(() => {
                    setIsLoading(false);
                });
        } else {
            alert(`Color ${defaultSettings} is default one and it is already set in the organization.
            Please, select a different one and try again.`);
        }
    }

    return (
        <div className={"UISettingsContainer"}>
            {defaultSettings && (
                <Stack>
                    <label style={{fontSize: "small"}}>Primary color</label>
                    <input
                        className={"ColorPicker"}
                        id={"ColorPicker"}
                        name={"ColorPicker"}
                        type={"color"}
                        defaultValue={defaultSettings}
                        onChange={event => setSettings(event.target.value)}
                    />
                    <LoadingButton
                        loading={isLoading}
                        loadingPosition={"center"}
                        onClick={postSettings}
                        style={{width: 90, margin: "0 auto"}}
                        loadingIndicator={
                            <CircularProgress style={{color: "white"}} size={16}/>
                        }
                        variant={"text"}
                    >
                        Save
                    </LoadingButton>
                </Stack>
            )}
            {!defaultSettings && <CircularProgress style={{marginTop: 50}}/>}
        </div>
    );
};
