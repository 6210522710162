import {Dispatch, useEffect, useState} from "react";
import {API_URL} from "../../../config";

export interface MaprdResultsInt {
    results: DataCaptureInt;
    success: boolean;
    message?: string;
    detail?: string;
}

export interface DataCaptureInt {
    enabled: boolean;
}

export const UseStateEnabled = (
    token: string,
    setError: Dispatch<string[] | null>
) => {
    const [data, setData] = useState<MaprdResultsInt | null>(null);
    const [enabled, setEnabled] = useState<boolean | null>(null);

    useEffect(() => {
        if (data?.results) {
            setEnabled(data.results.enabled);
        }
    }, [data]);

    useEffect(() => {
        fetch(`${API_URL}/data-capture-lock/`, {
            method: "GET",
            headers: {Authorization: token},
            credentials: "include",
        })
            .then(r => r.json())
            .then(response => {
                if (response.success) {
                    setData(response);
                } else {
                    setError([response.message, response.detail]);
                }
            })
            .catch(console.error);
    }, [setError, token]);

    return enabled;
};
