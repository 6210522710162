import React, {useState} from "react";
import {API_URL} from "../../config";
import "./Form.css";
import {Button, Typography} from "@mui/material";
import Stack from "@mui/material/Stack";
import {Application} from "../../components/Application";
import TooltipDropdown from "../QuestionAnswering/components/TooltipDropdown/TooltipDropdown";
import "./styles.css"
import CircularProgress from '@mui/material/CircularProgress';
import SendIcon from '@mui/icons-material/Send';


const DEReport = (props: { token: string }) => {
    const [, setPresentationUrl] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const [presentationName, setPresentationName] = useState("");
    const [trainingId, setTrainingId] = useState(0);
    const [expectedDocCount, setExpectedDocCount] = useState(0);

    const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPresentationName(event.target.value);
    };

    const handleChangeTraining = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTrainingId(Number(event.target.value));
    };

    const handleExpectedDocCount = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setExpectedDocCount(Number(event.target.value));
    };

    const handleClick = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        event.preventDefault();
        if (presentationName.length === 0) {
            alert("Presentation name is empty");
            return null;
        }
        if (trainingId <= 0) {
            alert("Training ID has to be positive number");
            return null;
        }
        if (expectedDocCount < 0) {
            alert("Effort reduction cannot be less than or equal to zero.");
            return null;
        }
        generatePresentation(presentationName, trainingId, expectedDocCount).then(
            r => r
        );
    };

    async function generatePresentation(
        presentationName: string,
        trainingId: Number,
        expectedDocCount: Number
    ) {
        setLoading(true);
        setError(null);
        await fetch(`${API_URL}/de-report/create`, {
            method: "POST",
            body: JSON.stringify({
                presentation_name: presentationName,
                training_id: trainingId,
                expected_doc_count: expectedDocCount,
            }),
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
                Authorization: props.token,
            },
        })
            .then(response => response.json())
            .then(response => {
                console.log(response);
                if (response.success) {
                    setPresentationUrl(response);
                    window.location.href = response.results;
                } else {
                    setError(response.message);
                    console.error(response.message);
                }
            })
            .finally(() => {
                setLoading(false);
            })
            .catch(error => {
                setError(error);
            });
    }

    return (
        <Application>
            <Typography variant={"h3"}>DE Report 3.0</Typography>
            {error && <div>Error: {error}</div>}
            <form autoComplete={"off"}>
                <Stack direction={"column"} alignItems={"center"} spacing={5}>
                    <input
                        id={"name"}
                        onChange={handleChangeName}
                        className={"input"}
                        name={"presentation_name"}
                        placeholder={"Name of your presentation"}
                        type={"text"}
                        key={"presentationName"}
                        required={true}
                    />
                    <input
                        id={"training"}
                        onChange={handleChangeTraining}
                        className={"input"}
                        name={"training_id"}
                        placeholder={"Training ID"}
                        type={"number"}
                        min={0}
                        key={"trainingId"}
                        required={true}
                    />
                    <TooltipDropdown label={"Expected document count"}>
                        <Stack width={600} alignItems={"center"}>
                            <input
                                id={"expected_doc_count"}
                                onChange={handleExpectedDocCount}
                                className={"input"}
                                name={"expected_doc_count"}
                                placeholder={"Expected document count"}
                                type={"number"}
                                min={1}
                                key={"expectedDocCount"}
                                defaultValue={0}
                                required={true}
                            />
                            <Typography variant={"body2"} width={350} color={"darkgrey"} textAlign={"center"}>
                                Specific expected document count
                            </Typography>
                            <Typography variant={"caption"} width={300} marginTop={3} textAlign={"left"}>
                                Setting the expected document count to zero will heuristically tries
                                to find out the number. It first search in the JIRA Epic, then uses
                                defaults set in Dojo during training initialization. If you wish to
                                set any other number, enter a number greater than zero. If you are
                                unsure, you should be good with default value.
                            </Typography>
                        </Stack>
                    </TooltipDropdown>
                    <Button
                        style={{width: 300}}
                        type={"submit"}
                        variant="contained"
                        color="primary"
                        startIcon={loading ? <CircularProgress size={20} color="inherit"/> : <SendIcon/>}
                        onClick={handleClick}
                        disabled={loading} // Disable the button while loading
                    >
                        {loading ? 'Generating report...' : 'Generate report'}
                    </Button>
                </Stack>
            </form>
        </Application>
    )
        ;
};

export default DEReport;
