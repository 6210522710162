const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === "development";

const servedOnSvc = window.location.pathname.includes("/svc/");
export const basename = servedOnSvc
    ? // /svc/data-matching-legacy/route => /svc/data-matching-legacy
    window.location.pathname
        .split("/")
        .filter(s => !!s)
        .slice(0, 2)
        .join("/")
    : undefined;

export const API_URL = isDev
    ? "http://localhost:5000"
    : servedOnSvc
        ? `${window.location.origin}/${basename}`
        : "";
