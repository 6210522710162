import {useEffect, useState} from "react";
import {API_URL} from "../../../config";

export const useOrganizationSettings = (token: string, suffix_url: string) => {
    const [settings, setSettings] = useState<string | null>(null);

    useEffect(() => {
        fetch(`${API_URL}/ui-settings/${suffix_url}`, {
            headers: {Authorization: token},
            credentials: "include",
        })
            .then(r => r.json())
            .then(response => setSettings(response.results))
            .catch(console.error);
    }, [token, suffix_url]);
    return settings;
};
