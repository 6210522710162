import React, {useEffect, useState} from "react";
import {AAHook, AppProps} from "../types";
import {Queue} from "../../types";
import {BiCheck} from "react-icons/bi";
import {addAAssistant, getAAssistants, removeAAssistant} from "./lib";
import {API_URL} from "../../config";
import "./styles.css";
import {filter, includes, map} from "lodash";
import {mergeStyles} from "../../lib";
import {Typography} from "@mui/material";
import {Application} from "../../components/Application";

const AnnotationAssistant = ({token}: AppProps) => {
    const [hooks, setHooks] = useState<AAHook[]>([]);
    const [queues, setQueues] = useState<Queue[]>([]);
    const [queueInProgress, setQueueInProgress] = useState<number | null>(null);

    useEffect(() => {
        fetch(`${API_URL}/api/rossum/queues`, {
            headers: {Authorization: token},
            credentials: "include",
        })
            .then(r => r.json())
            .then(response => setQueues(response.results));
    }, [token]);

    useEffect(() => {
        getAAssistants(token).then(({hooks}) => setHooks(hooks));
    }, [token]);

    const onRemove = (queueId: number) => {
        setQueueInProgress(queueId);
        removeAAssistant(queueId, token)
            .then(({success}) => {
                if (success) setHooks(filter(hooks, ({queue}) => queue !== queueId));
            })
            .finally(() => setQueueInProgress(null));
    };

    const onAdd = (queueId: number) => {
        setQueueInProgress(queueId);
        addAAssistant(queueId, token)
            .then(({success}) => {
                if (success) setHooks([...hooks, {queue: queueId}]);
            })
            .finally(() => setQueueInProgress(null));
    };

    return (
        <Application>
            <Typography variant={"h3"}>Annotation Assistant</Typography>
            <Typography variant={"body2"} style={{width: 600}}>
                Annotation Assistant is a tool that warns annotators (users of the
                Rossum app) about annotation malpractices as they are making them, by
                showing messages in the extraction view.
            </Typography>
            {queues.map(({name, id}, i) => {
                const active = includes(map(hooks, "queue"), id);
                const inProgress = queueInProgress === id;
                return (
                    <button
                        onClick={() => (active ? onRemove(id) : onAdd(id))}
                        autoFocus={i === 0}
                        className={mergeStyles([
                            "AA-Row",
                            active && "AA-Row-active",
                            inProgress && "AA-Row-in-progress",
                        ])}
                        style={{width: "80%"}}
                    >
                        <div className="AA-Row-Name">
                            {name} ({id})
                        </div>
                        <BiCheck size={22} color={active ? "#40af61" : "#909090"}/>
                    </button>
                );
            })}
        </Application>
    );
};

export default AnnotationAssistant;
