import {chunk, first, isArray, keys} from "lodash";
import {MdKeyboardReturn} from "react-icons/md";
import {CSSTransition} from "react-transition-group";
import {parse} from "query-string";
import React, {Dispatch, KeyboardEventHandler, useEffect, useRef, useState,} from "react";
import "./App.css";
import OrgPanel from "./components/OrgPanel";
import AppName from "./components/AppsName";
import Logout from "./apps/Logout";
import {API_URL} from "./config";
import {User} from "./types";
import Stack from "@mui/material/Stack";
import {Avatar, Button, Typography} from "@mui/material";
import Chip from "@mui/material/Chip";
import {nameInitials} from "./utils";
import QuestionAnswering from "./apps/QuestionAnswering";
import SchemaVersioning from "./apps/SchemaVersioning";
import SchemaBreakdown from "./apps/SchemaBreakdown";
import FieldHistogram from "./apps/FieldHistogram";
import UpdateRirFieldNames from "./apps/UpdateRirFieldNames";
import AnnotationAssistant from "./apps/AnnotationAssistant";
import DEReport from "./apps/DEReport";
import UISettings from "./apps/UISettings";
import DataCaptureLock from "./apps/DataCaptureLock";
import {AccuracyReport} from "./apps/AccuracyReport";
import {CircularProgress} from "@material-ui/core";
import {UserDocumentation} from "./apps/UserDocumentation";

const ROW_SIZE = 4;

const appRouter: { [appName: string]: Function } = {
    "Schema Breakdown": SchemaBreakdown,
    "Schema Versioning": SchemaVersioning,
    "Field Histogram": FieldHistogram,
    "Update rir_field_names": UpdateRirFieldNames,
    "Annotation Assistant": AnnotationAssistant,
    "DE Report": DEReport,
    "UI Settings": UISettings,
    "Data Capture Lock": DataCaptureLock,
    "Accuracy Report": AccuracyReport,
    // "Question Answering": QuestionAnswering,
    "Logout organization": Logout,
    "User documentation": UserDocumentation
};

const APPS = keys(appRouter);

const search = (phrase: string, phrases: string[]) =>
    phrases.filter(p => {
        const words = phrase.toLowerCase().split(" ");
        const matches = words.filter(word => p.toLowerCase().includes(word));
        return words.length === matches.length;
    });

const getDefaultToken = (_setIsElisToken: Dispatch<boolean>) => {
    const {authToken} = parse(window.location.hash);
    // If a token is passed through the URL params set is from Elis
    if (authToken) {
        _setIsElisToken(true);
    }
    const currentToken = authToken || localStorage.getItem("token");
    if (currentToken === authToken) {
        window.location.hash = "";
    }
    return isArray(currentToken) ? first(currentToken) : currentToken;
};

const App = () => {
    const searchRef = useRef<HTMLInputElement>(null);
    const [appFilter, setAppFilter] = useState("");
    const [selectedApp, setSelectedApp] = useState("");
    const [username, _setUsername] = useState("");
    const [index, setIndex] = useState<[number, number]>([0, 0]);
    const [isElisToken, _setIsElisToken] = useState<boolean>(false);
    const [token, _setToken] = useState<string | null>(null);
    const [isAuthorized, setIsAuthorized] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [user, setUser] = useState<User | null>(null);

    const setToken = (_token: string) => {
        _setToken(_token);
        localStorage.setItem("token", _token);
    };

    const setUsername = (_username: string) => {
        _setUsername(_username);
        localStorage.setItem("username", _username);
    };

    useEffect(() => {
        // Try to authorize the user with the provided token
        fetch(`${API_URL}/user`, {
            method: "GET",
            credentials: "include",
        })
            .then(response => response.json())
            .then(content => {
                if (content.success) {
                    setUser(content.user as User);
                }
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const defaultToken = getDefaultToken(_setIsElisToken);
        if (defaultToken) setToken(defaultToken);
    }, [isElisToken]);

    useEffect(() => {
        if (token) {
            setIsLoading(true);
            fetch(`${API_URL}/api/rossum/organizations`, {
                method: "GET",
                headers: {Authorization: token || ""},
                credentials: "include",
            })
                .then(r => r.json())
                .then((response: any) => {
                    setIsAuthorized(true);
                    setUsername(response.results[0].name);
                })
                .catch(error => {
                    console.error(error);
                    setIsAuthorized(false);
                })
                .finally(() => setIsLoading(false));
        }
    }, [token]);

    const searchedApps = search(appFilter, APPS);
    const appRows = chunk(searchedApps, ROW_SIZE);

    const onAppChange = (app: string) => {
        if (searchRef.current) searchRef.current.blur();
        setAppFilter("");
        setIndex([0, 0]);
        setSelectedApp(app);
    };

    const keyPressMapping: { [key: string]: KeyboardEventHandler } = {
        Enter: () => onAppChange(appRows[index[0]][index[1]]),
        Tab: e => {
            e.preventDefault();
            const [rowIndex, columnIndex] = index;
            const rowSize = appRows[0] ? appRows[0].length : 0;
            if (e.shiftKey) {
                const isFirstRow = rowIndex === 0;
                const isFirstColumn = columnIndex === 0;
                const newRowIndex = isFirstColumn
                    ? isFirstRow
                        ? appRows.length - 1
                        : rowIndex - 1
                    : rowIndex;
                const newColumnIndex = isFirstColumn ? rowSize - 1 : columnIndex - 1;
                setIndex([newRowIndex, newColumnIndex]);
            } else {
                const isLastRow = rowIndex === appRows.length - 1;
                const isLastColumn = columnIndex === rowSize - 1;
                const newRowIndex = isLastColumn
                    ? isLastRow
                        ? 0
                        : rowIndex + 1
                    : rowIndex;
                const newColumnIndex = isLastColumn ? 0 : columnIndex + 1;
                setIndex([newRowIndex, newColumnIndex]);
            }
        },
    };

    const onAppFilterChange = ({
                                   target: {value},
                               }: React.ChangeEvent<HTMLInputElement>) => {
        setIndex([0, 0]);
        setAppFilter(value);
    };

    const onLogout = () => {
        setUsername("");
        setToken("");
        setSelectedApp("");
        setIsAuthorized(false);
        _setIsElisToken(false);
    };

    const SelectedApp = selectedApp ? appRouter[selectedApp] : () => null;
    return (
        <div className="App">
            {!!username && <OrgPanel onLogout={onLogout} username={username}/>}
            {!!user && (
                <>
                    <Chip
                        className={"UserPanel"}
                        avatar={<Avatar>{nameInitials(user.fullname)}</Avatar>}
                        label={user.username}
                        style={{width: 200}}
                    />
                    <form
                        className={"LogoutPanel"}
                        action={`${API_URL}/logout`}
                        method="post"
                    >
                        <Button type="submit" variant="text" style={{width: 200}}>
                            Logout from MAPRD
                        </Button>
                    </form>
                </>
            )}
            <header className="App-header">
                <CSSTransition
                    in={!selectedApp}
                    timeout={1000}
                    classNames="TitleArea"
                >
                    <h1
                        style={{marginTop: selectedApp ? undefined : "10%"}}
                        className="Title"
                    >
                        MAPRD
                    </h1>
                </CSSTransition>
                <div>
                    {user ? (
                        <div>
                            {!isAuthorized ? (
                                <div className="LoginComponent">
                                    {isLoading ?
                                        <CircularProgress/>
                                        : (
                                            <>
                                                <h3>No organization attached to MAPRD</h3>
                                                <Typography variant={"body1"}>
                                                    You are logged in to MAPRD, but in order to see an application
                                                    list, you have to login to some organization first.
                                                </Typography>
                                                <Typography variant={"body1"}>Please, login to some organization with
                                                    Django Admin support access button (also known as a MAPRD button):
                                                </Typography>
                                                <Stack alignItems={"center"} marginTop={5}>
                                                    <Stack direction={"row"} spacing={3}>
                                                        <a href={"https://elis.rossum.ai/api/c483f/api/organization/support_access"}
                                                           target={"_blank"} rel="noreferrer">
                                                            <Button className={"Button"}
                                                                    variant={"contained"}>EU</Button>
                                                        </a>
                                                        <a href={"https://shared-eu2.rossum.app/api/c483f/login/?next=/api/c483f/"}
                                                           target={"_blank"} rel="noreferrer">
                                                            <Button className={"Button"}
                                                                    variant={"contained"}>EU2</Button>
                                                        </a>
                                                        <a href={"https://us.app.rossum.ai/api/c483f/login/?next=/api/c483f/"}
                                                           target={"_blank"} rel="noreferrer">
                                                            <Button className={"Button"}
                                                                    variant={"contained"}>US2</Button>
                                                        </a>
                                                        <a href={"https://nn-group.app.rossum.ai/api/c483f/login/?next=/api/c483f/"}
                                                           target={"_blank"} rel="noreferrer">
                                                            <Button className={"Button"}
                                                                    variant={"contained"}>NN</Button>
                                                        </a>
                                                        <a href={"https://shared-jp.app.rossum.ai/api/c483f/login/?next=/api/c483f/"}
                                                           target={"_blank"} rel="noreferrer">
                                                            <Button className={"Button"}
                                                                    variant={"contained"}>JP</Button>
                                                        </a>
                                                    </Stack>
                                                </Stack>
                                            </>
                                        )}
                                </div>
                            ) : (
                                <React.Fragment>
                                    <CSSTransition
                                        in={!selectedApp}
                                        timeout={1000}
                                        classNames="SearchArea"
                                    >
                                        <div className="Search">
                                            <input
                                                className="Input"
                                                value={appFilter}
                                                ref={searchRef}
                                                placeholder={
                                                    selectedApp
                                                        ? "Press Tab for Search"
                                                        : "Type app name"
                                                }
                                                onFocus={() => {
                                                    setSelectedApp("");
                                                }}
                                                autoFocus
                                                onKeyDown={e => {
                                                    if (keyPressMapping[e.key])
                                                        keyPressMapping[e.key](e);
                                                }}
                                                onChange={onAppFilterChange}
                                            />
                                            <div className="SearchHint">
                                                <MdKeyboardReturn/>
                                                (Shift) Tab to navigate
                                            </div>
                                        </div>
                                    </CSSTransition>
                                    {selectedApp ? (
                                        <SelectedApp
                                            username={username}
                                            onLogout={onLogout}
                                            token={token}
                                            isElisToken={isElisToken}
                                            deselectApp={() => setSelectedApp("")}
                                        />
                                    ) : (
                                        <div className="AppsNames">
                                            {appRows.map((apps, rowIndex) => (
                                                <div key={String(apps)} className="AppRow">
                                                    {apps.map((app, columnIndex) => (
                                                        <AppName
                                                            appFilter={appFilter}
                                                            appName={app}
                                                            columnIndex={columnIndex}
                                                            rowIndex={rowIndex}
                                                            index={index}
                                                            setIndex={setIndex}
                                                            onAppChange={onAppChange}
                                                            key={app}
                                                        />
                                                    ))}
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </React.Fragment>
                            )}
                        </div>
                    ) : (
                        <Stack>
                            <Typography variant="body2">
                                Use SSO to login to MAPRD.
                            </Typography>
                            <form action={`${API_URL}/login`} method="get">
                                <Button type="submit" variant="contained">
                                    SSO Login
                                </Button>
                            </form>
                        </Stack>
                    )}
                </div>
            </header>
        </div>
    );
};

export default App;
