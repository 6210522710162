import React, {useEffect, useState} from "react";
import toast from "react-simple-toasts";
import FieldPicker from "../../components/FieldPicker";
import {MultiQueue as QueuePicker} from "../../components/QueuePicker";
import {AppProps} from "../types";
import {Field, Queue} from "../../types";
import {API_URL} from "../../config";
import {map} from "lodash";
import {Application} from "../../components/Application";

const UpdateRirFieldNames = ({token, deselectApp}: AppProps) => {
    const [queues, setQueues] = useState<Queue[]>([]);
    const [field, setField] = useState<Field | null>(null);
    const [value, setValue] = useState("");

    useEffect(() => {
        setField(null);
    }, [queues]);

    const updateSchemas = () => {
        fetch(`${API_URL}/api/schema-breakdown`, {
            method: "POST",
            body: JSON.stringify({
                rir_field_names: value,
                schema_id: field?.id,
                schemas: map(queues, "schema"),
            }),
            credentials: "include",
            headers: {Authorization: token, "Content-Type": "application/json"},
        })
            .then(() => {
                deselectApp();
                toast(`Successfully updated ${queues.length} schemas`);
            })
            .catch(e => {
                console.error(e);
                toast(`Schema update failed`);
            });
    };

    return (
        <Application>
            <h1>Update rir_field_names</h1>
            <QueuePicker token={token} setQueues={setQueues} currentQueues={queues}/>
            {!!queues.length && (
                <FieldPicker
                    queues={queues}
                    token={token}
                    field={field}
                    setField={setField}
                />
            )}
            {field && !!queues.length && (
                <div className="Selection">
                    <label>New 'rir_field_names'</label>
                    <input
                        className="TextInput"
                        value={value}
                        onChange={event => setValue(event.target.value)}
                        autoFocus
                    />
                    <div className="Hint">If multiple, separate by comma</div>
                </div>
            )}
            {queues && field && value && (
                <button
                    onClick={updateSchemas}
                    className="Button"
                    style={{width: 250, margin: "auto"}}
                >
                    Update Schemas
                </button>
            )}
        </Application>
    );
};

export default UpdateRirFieldNames;
