import React from "react";
import Stack from "@mui/material/Stack";

export const Application = ({children}: any) => {
    return <Stack direction={'column'}
                  alignItems={'center'}
                  spacing={2}
                  marginTop={-15}
                  marginBottom={"3em"}
                  style={{display: 'flex'}}
    >
        {children}
    </Stack>
}
