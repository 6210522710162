import {useEffect, useState} from "react";
import {API_URL} from "../../../config";

export const UseOrganizationID = (token: string, username: string) => {
    const [data, setData] = useState<number | null>(null);

    useEffect(() => {
        fetch(`${API_URL}/data-capture-lock/id/${username}`, {
            method: "GET",
            headers: {Authorization: token},
            credentials: "include",
        })
            .then(r => r.json())
            .then(response => {
                if (response.success) {
                    setData(response.results);
                }
            })
            .catch(console.error);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token]);

    return data;
};
