import {AppProps} from '../types'
import Stack from '@mui/material/Stack'
import React, {useEffect} from 'react'
import {useAgendaList} from './hooks/useAgendaList'
import CircularProgress from '@mui/material/CircularProgress'
import {getAccuracyReport} from './hooks/getAccuracyReport'
import {LoadingButton} from '@mui/lab'
import {Message} from './interfaces/message'
import {ErrorInterface} from './interfaces/error'
import {Typography} from '@mui/material'
import {AccuracyReportApiInterface} from './interfaces/data'
import {FieldType} from './interfaces/source'
import {selectData} from './components/AccuracyChart/controller'
import {AccuracyHeader} from './components/AccuracyHeader/AccuracyHeader'
import {AlertSnackbar} from './components/AlertSnackbar/AlertSnackbar'
import {SourceSelector} from './components/SourceSelector/SourceSelector'
import {ChartScaler} from './components/ChartScaler/ChartScaler'
import {AgendaSelector} from './components/AgendaSelector/AgendaSelector'
import {AccuracyChart} from './components/AccuracyChart/AccuracyChart'
import {Application} from "../../components/Application";

export const AccuracyReport = ({token}: AppProps) => {
    const [agenda, setAgenda] = React.useState('')
    const [isLoading, setIsLoading] = React.useState(false)
    const [data, setData] = React.useState<AccuracyReportApiInterface | null>(null)
    const [error, setError] = React.useState<ErrorInterface | null>(null)
    const [source, setSource] = React.useState<FieldType>(FieldType.HeaderField)
    const [scale, setScale] = React.useState(100)

    const agendas = useAgendaList(token, setError, setIsLoading)

    const onClickGenerate = () => {
        if (agenda) {
            setIsLoading(true)
            getAccuracyReport(token, agenda)
                .then((report) => {
                    if (report.success) {
                        setData(report)
                    } else {
                        setError({
                            message: report.message,
                            detail: report.detail,
                            severity: 'error',
                        } as Message)
                    }
                })
                .finally(() => setIsLoading(false))
        }
    }

    // TODO: This useEffect hook may be an adept for refactoring
    useEffect(() => {
        if (data) {
            const resources = selectData(data, source)
            // Check the data and if they are empty, inform the user
            if (resources.length === 0) {
                const message = {
                    message: 'No data found',
                    detail: `For ${
                        source === FieldType.HeaderField ? 'header fields' : 'line items'
                    } there are ${resources.length} data that can be visualized.`,
                    severity: 'error',
                } as ErrorInterface
                setError(message)
            }

            // Check if we have enough data to show
            // @ts-ignore
            const uniqueFieldNames = [...new Set(resources.map((item) => item.fieldName))]
            for (let i = 0; i < uniqueFieldNames.length; i++) {
                const fieldName = uniqueFieldNames[i]
                const filtered = resources.filter((item) => {
                    return item.fieldName === fieldName
                })
                if (filtered.length <= 1) {
                    const message = {
                        message: `Some field(s) has not enough data to show.`,
                        detail: `For example, field ${fieldName} has only ${filtered.length} datapoint, so it cannot be visualized.`,
                        severity: 'warning',
                    } as ErrorInterface
                    setError(message)
                }
            }
        }
    }, [data, source])

    return (
        <Application>
            <AccuracyHeader/>
            <AlertSnackbar error={error} setError={setError}/>
            {data && data ? (
                    <Stack alignItems={'center'}>
                        <Stack direction={'row'}>
                            <SourceSelector source={source} setSource={setSource}/>
                            <ChartScaler value={scale} setValue={setScale}/>
                        </Stack>
                        <AccuracyChart
                            data={data}
                            agenda={agenda}
                            source={source}
                            scale={scale}
                        />
                    </Stack>
                ) :
                (
                    <div style={{width: '100%'}}>
                        {!agendas && isLoading && (
                            <Stack alignItems={'center'} spacing={2}>
                                <CircularProgress/>
                                <Typography variant={'body1'}>Loading agendas...</Typography>
                            </Stack>
                        )}
                        {agendas && (
                            <AgendaSelector
                                agenda={agenda}
                                agendas={agendas}
                                setAgenda={setAgenda}
                            />
                        )}
                        {agenda && (
                            <LoadingButton
                                variant={'contained'}
                                onClick={onClickGenerate}
                                loading={isLoading}
                                disabled={isLoading && !data}
                                style={{marginTop: 20, width: '100%'}}
                            >
                                Generate
                            </LoadingButton>
                        )}
                    </div>
                )}
        </Application>
    )
}
