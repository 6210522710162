import React from "react";

export class ErrorDisplay extends React.Component<{ error: any }> {
    render() {
        return (
            <>
                {this.props.error && <p style={{color: "red"}}>{this.props.error}</p>}
            </>
        );
    }
}
